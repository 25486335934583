<template>
  <section class="section numbers-section">
    <div class="container">
      <div class="numbers-container">
        <div
          class="number-tile"
          v-for="(number, i) in $store.state.siteData.numbers"
          :key="i"
        >
          <div class="number-tile_icon">
            <img :src="getIconUrl(number.icon)" alt="" />
          </div>
          <div class="number-tile_count">
            <div>{{ number.count }}</div>
          </div>
          <div class="number-tile_title">
            <div>{{ number.title }}</div>
          </div>
          <hr />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Numbers",
  props: {
    icon: String,
    title: String,
    count: String,
  },
  methods: {
    getIconUrl(name) {
      var img = require.context("@/assets/images/", false, /\.svg$/);
      return img("./" + name + ".svg");
    },
  },
};
</script>

<style lang="scss" scoped>
.numbers-section {
  background-color: $secondary-color;

  .numbers-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    padding: 6rem 0;

    .number-tile {
      color: $white;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;

      hr {
        display: none;
      }

      &_icon {
        width: 5rem;
        height: 5rem;
        position: relative;
        margin-bottom: 2rem;
        transform: rotate(0);
        transition: 500ms cubic-bezier(0.3, 1, 0.7, 1);

        &::after {
          content: "";
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          bottom: -2rem;
          background-color: $primary-color;
          height: 4px;
          width: 50%;
          border-radius: 4px;
        }
      }

      &_count {
        margin: 1rem 0;
        font-size: 3.2rem;
        font-weight: 800;
      }

      &_title {
        font-size: 1.1rem;
        font-weight: 700;
        text-transform: uppercase;
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .numbers-section {
    .numbers-container {
      grid-template-columns: repeat(2, 1fr);
      gap: 6rem 0;
    }
  }
}

@media screen and (max-width: 768px) {
  .numbers-section {
    .numbers-container {
      .number-tile {
        &_icon {
          width: 4rem;
          height: 4rem;
        }

        &_count {
          font-size: 2.5rem;
        }

        &_title {
          font-size: 1rem;
        }
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .numbers-section {
    .numbers-container {
      grid-template-columns: repeat(1, 1fr);
      gap: 4rem 0;
      .number-tile {
        hr {
          display: block;
          width: 90%;
          margin: 0 auto;
          opacity: 0.2;
          margin-top: 4rem;
        }
        &_count {
          font-size: 2.4rem;
        }

        &_title {
          font-size: 0.875rem;
        }
      }
    }
  }
}
</style>
