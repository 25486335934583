<template>
  <main>
    <section class="section hero-section">
      <div class="container">
        <div class="hero">
          <h2 class="hero__title">ABOUT US</h2>
        </div>
      </div>
    </section>
    <section class="section about-section">
      <div class="container">
        <div class="about">
          <div class="about-image">
            <div class="image">
              <img
                src="@/assets/images/office.webp"
                alt="Agile Vision Developers Company"
              />
            </div>
          </div>
          <div class="about-content">
            <Subtitle>Company</Subtitle>
            <Title>{{ $store.state.siteData.pages.about_us.title }}</Title>
            <p>
              {{ $store.state.siteData.pages.about_us.text[0] }}
              <br /><br />
              {{ $store.state.siteData.pages.about_us.text[1] }}
            </p>
          </div>
        </div>
      </div>
    </section>
    <!-- <section class="section benefits-section">
      <div class="container">
        <div class="benefits-container">
          <div class="section-head">
            <Subtitle>Our Services</Subtitle>
            <Title>
              We provide you with high quality <br />
              technology services
            </Title>
          </div>
          <div class="benefits-grid">
            <div
              class="grid-item"
              v-for="(item, i) in $store.state.siteData.key_benefits.benefits"
              :key="i"
            >
              <Card
                :icon="item.icon"
                :title="item.title"
                :text="item.text"
                :theme="i % 2 ? 'dark' : 'light'"
              />
            </div>
          </div>
        </div>
      </div>
    </section> -->
    <Numbers />
    <Gallery />
  </main>
</template>

<script>
// import Card from "@/components/utils/Card.vue";
import Numbers from "@/components/layout/common/Numbers.vue";
import Gallery from "@/components/layout/common/Gallery.vue";
import Subtitle from "@/components/utils/Subtitle.vue";
import Title from "@/components/utils/Title.vue";
import { mapActions } from "vuex";

export default {
  name: "AboutPage",
  components: { Numbers, Gallery, Subtitle, Title },
  metaInfo: {
    title:
      "The only technology partner you will ever need | Enterprise Solutions",
    meta: [
      {
        name: "description",
        content:
          "AV DEVS is a one stop solution provider for any technology development needs. We are the only technology partner you would ever need. We engage dedicated resources in technologies like PHP, Laravel, .Net, Java, Python, Node.js, React.js, Vue.js, Angular, iOS Swift, Android Kotlin, React native, QA with Automation, DevOps, Machine Learning and AI, Graphic design and Digital Marketing. ",
      },
      {
        name: "og:title",
        content:
          "The only technology partner you will ever need | Enterprise Solutions",
      },
      {
        name: "og:description",
        content:
          "AV DEVS is a one stop solution provider for any technology development needs. We are the only technology partner you would ever need. We engage dedicated resources in technologies like PHP, Laravel, .Net, Java, Python, Node.js, React.js, Vue.js, Angular, iOS Swift, Android Kotlin, React native, QA with Automation, DevOps, Machine Learning and AI, Graphic design and Digital Marketing. ",
      },
    ],
  },
  methods: {
    ...mapActions(["fillTargets"]),
  },
  mounted() {
    this.fillTargets();
  },
};
</script>

<style lang="scss" scoped>
.hero-section {
  background: url("~@/assets/images/about-bg.webp") center/cover;
}

.benefits-container {
  .benefits-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 4rem;
  }
}

.benefits-container {
  text-align: center;
  padding-bottom: 3.2rem;
}

@media screen and (max-width: 1200px) {
  .benefits-container {
    .benefits-grid {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

@media screen and (max-width: 576px) {
  .benefits-container {
    .benefits-grid {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}
</style>
